import {ListResponse} from '@wix/ambassador-addresses-api-v1-address/types';
import {MemberAddressModel} from './MemberAddress.model';
import {mapToAddressWithContactModel} from '../utils/billingDetails.utils';
import {AddressWithContactModel} from './AddressWithContact.model';

export class MemberAddressesInfoModel {
  public addresses: AddressWithContactModel[];
  public defaultAddressId?: string;

  constructor({addresses, defaultAddressId}: ListResponse) {
    this.addresses = (addresses ?? [])
      .map((address) => new MemberAddressModel(address))
      .map((memberAddress) => mapToAddressWithContactModel(memberAddress));
    this.defaultAddressId = defaultAddressId ?? '';
  }
}
