import {ControllerFlowAPI} from '@wix/yoshi-flow-editor';
import {MemberService} from '../services/MemberService';
import {MemberStoreProps} from '../../types/app.types';
import {NavigationService} from '../services/NavigationService';
import {CheckoutService} from '../services/CheckoutService';
import {AddressWithContactModel} from '../models/AddressWithContact.model';

export type MemberStoreConfig = {
  flowAPI: ControllerFlowAPI;
  memberService: MemberService;
  navigationService: NavigationService;
  checkoutService: CheckoutService;
  updateComponent: () => void;
};

export class MemberStore {
  private readonly flowAPI: ControllerFlowAPI;
  private readonly memberService: MemberService;
  private readonly navigationService: NavigationService;
  private readonly checkoutService: CheckoutService;

  private readonly updateComponent: () => void;

  constructor({flowAPI, memberService, navigationService, checkoutService, updateComponent}: MemberStoreConfig) {
    this.flowAPI = flowAPI;
    this.memberService = memberService;
    this.navigationService = navigationService;
    this.checkoutService = checkoutService;
    this.updateComponent = updateComponent;
    if (!this.navigationService.isFastFlow) {
      void this.init();
    }
  }

  private async init() {
    await this.memberService.setIsAddressesAppInstalled();
    if (this.memberService.isMember() && this.memberService.isAddressesAppInstalled) {
      await this.memberService.getCurrentUserEmail();
      await this.memberService.fetchAddressesInfo();
    }
    /* istanbul ignore next */
    this.checkoutService.checkout && this.updateComponent();
  }

  private readonly getDefaultAddress = (): AddressWithContactModel | undefined => {
    return this.memberService.addressesInfo.addresses.filter(
      (address) => address.addressesServiceId === this.memberService.addressesInfo.defaultAddressId
    )?.[0];
  };

  public toProps(): MemberStoreProps {
    return {
      isMember: this.memberService.isMember(),
      addressesInfo: this.memberService.addressesInfo,
      defaultAddress: this.getDefaultAddress(),
      shouldShowMemberInfoBox: this.memberService.isAddressesAppInstalled,
      currentUserEmail: this.memberService.currentUserEmail,
    };
  }
}
